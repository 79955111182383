.cancel-popup-main-buttons {
    display: flex;
    justify-content: center;
}
.ics-confirm{
    bottom: 10px; /* Distance from the bottom of the container */
    right: 10px; /* Distance from the right side of the container */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    top: 90%;
    position: absolute;
}
.cancel-popup-secondary-buttons {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.btn-addcalendar {
    min-width: 120px;
}

.confirm-popup, .cancel-popup{
    margin: auto;
    margin-top: 15px;
    width: 90%;
}
.confirm-popup button, .cancel-popup button{
    margin-left: 5%;
    margin-right: 5%;
}

.cancel-popup button{
    max-width: 45%;
}

.confirm-popup .btn-cancel, .cancel-popup .btn-cancel{
    background: black;
    margin: 10px !important;


}
.confirm-popup .btn-cancel, .cancel-popup .btn-cancel:hover{
    background: black !important;

}

.table-footer-show{
    margin-right: 20px;
    padding-top: 0.5%;
    font-size: 16px;
    font-family: sans-serif;
}


.btn-confirm{
    background: #444AE9;
    margin: 10px !important;
}

.btn-delete{
    background: #ef1e41 !important;
    margin: 10px !important;

}

#btn-unbook{
    background: #FF8000;
    padding-left: 10px;
    padding-right: 10px;
}

.popup-questionmark{
    transform: scale(2);
    margin-bottom: 0.65em;
}

@media only screen and (max-width: 380px) {
    .table-footer-choosingbtn > div > button {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 600px) {
    .table-footer-container{
        display: block;
        margin: auto;
    }
    .table-footer-choosingbtn{
        display: block;
    }

    .table-footer-choosingbtn div{
        display: block;
    }

    .table-footer-choosingbtn > div > button {
        width: 100% !important;
        font-size: 20px;
        border-radius: 50px;
        margin-left: 0 !important;
        margin-top: 5px !important;
    }

    .table-footer-confirm{
        display: block;
        margin-top: 20px;
    }

    .table-button:disabled {
        width: 100% !important;
        font-size: 15px !important;

    }

    .confirm-popup{
        display: block;
    }

    .btn-confirm, .btn-cancel, .btn-delete{
        width: 85%;
        font-size: 20px !important;
        margin-top: 5px !important;
        margin: 10px !important;

    }
}

@media only screen and (max-width: 800px) {
    .table-footer-container{
        width: 95%;

    }
}
/* POPUP */

.popup{
    background-color: rgba(242, 242, 255, 0.4);

}

.error{
    color: red !important;
}
.pop-up-box {
    text-align: center;
    color: #151516;
    border: 1px solid #F2F2FF;
    box-shadow: -9px 9px 20px rgba(158, 159, 184, 0.09);
    border-radius: 5px;
    width: 600px !important;
}

.pop-up-box:focus-visible{
    outline: 0;
}

.pop-up-box > p{
    text-align: left;
}

.pop-up-close{
    font-size: 15px;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    right: 3vh;
    top: 2vh;
}

.pop-up-box > div > button{
    background: #444AE9;
    font-size: 0.85em !important;
    display: block;
    margin: auto;
    width: 90%;
    height: 3em;
    border-radius: 8px;

}

.pop-up-reschedule{
    background: #151516 !important;
    margin-top: 0.5em !important;
}

.pop-up-cong-logo{
    color: #444AE9;
    transform: rotate(-0.59deg);
    font-size: 6em !important;
}

.modal-modal-description{
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 1.20em;
}
@media only screen and (max-width: 800px) {
    .pop-up-box{
        max-width: 95vw;
        display: inline !important;
    }
    .pop-up-box button{
        height: 50px;
        width: 100px;
        font-size: 10pt !important;
    }
}