.arrow{
    color: #151516;
    height: 15px !important;
    margin-top: 1em;
    cursor: pointer;
}

.arrow:hover{
    color: #1976d2;
    cursor: pointer;
}

.number {
    line-height: 1;
    margin: auto;
    color: #696969;
}

.number-faded {
    line-height: 1;
    margin: auto;
    color: #9F9F9F;
}

.months {
    line-height: 1;
    margin: auto;
    color: #696969;
}

.months-faded {
    line-height: 1;
    margin: auto;
    color: #9F9F9F;
  }

.days-name{
    border-bottom: none;
    font-size: 18px;
    color: black;
    font-weight: 700;
}

.date-container{
    font-family: BallyThrill_Bd;
    padding: 5px;
    background-color: #f3f3f3;
    height: 65px;
}

.days-name-faded{
    border-bottom: none;
    font-weight: 400;
    font-size: 18px;
    color: #9F9F9F;
}

.buttons-container{
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 5px;
    right: 0;
    top: 0;
}

.book-button, .cancelbooking-button{
    text-align: center;
    right: 0;
}

.unbook-button{
    background-color: #ff0000;
    color: white;
    padding: 10px 15px 10px 15px;
    width: fit-content;
}

.unbook-button:hover{
    background-color: #CC0000;
    cursor: pointer;
}

.default-button{
    background-color: #f3f3f3;
    color: black;
    padding: 2px 15px 1px 15px;
    min-width: 90px;
    font-size: 12px;
}

.default-button:hover{
    background-color: #ececec;
    color: black;
    cursor: pointer;
}

.selected-button{
    background-color: #1a8fff;
    color: black;
    cursor: pointer;
}

.selected-button:hover{
    background-color: #57adfa;
    color: black;
    cursor: pointer;
}

.appointment_time{
    position: relative;
}

.appointment_time:hover{
    background-color: #1a8fff;
    color: black !important;
    cursor: pointer;
    transition: 80ms ease-in;
}

.appointmentContent {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-family: BallyThrill_Rg;
    max-width: 200px;
    overflow-x: hidden;
}

.time, .title, .name{
    padding-left: 15px;
    text-align: left;
}

.name {
    margin-top: 2px;
    opacity: 70%;
}


.time{
    font-size: 16px;
    font-weight: bold;
}

.tag{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    height: 100%;
}

.calendar-buttons{
    /* Change their z level to ebe further back; */
    z-index: -5;
}


.list-table{
    box-shadow: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #e8e8e8;
}

.list-header{
    font-family: BallyThrill_Rg;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 20px;
}

.list-row{
    position: relative;
}

.list-row:hover{
    cursor: pointer;
    background-color: #f3f3f3;
}