.trade-request-container {
    position: fixed;
    right: 85px;
    top: -16px;
    z-index: 1000;
    font-family: BallyThrill_CdXBd, serif;
    padding: 8px;
    margin: 0;
}


.trade-req-text {
    color: #575757;
    font-family: BallyThrill_CdXBd, serif;
    font-size: 30px;
    padding: 15px;
    top: 100px;
}
.trade-req-text:hover {
    color: var(--bally_red);
    background-color: #200833;

}


.trade-request-btn {
    background-color: #1976d2;
    color: white;
    padding: 0;
    padding-inline: 0;
    padding-block: 0;
}

.trade-request-table {
    margin-top: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    height: 85vh;
    overflow-y: scroll;
}

.trade-request-section {
    margin-bottom: 16px;
}

.trade-request-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 8px;
}

.trade-request-message {
    flex-grow: 1;
    margin-left: 16px;
    margin-right: 16px;
}

.trade-request-actions {
    display: flex;
    align-items: center;
}

.accept-btn, .decline-btn {
    margin-left: 8px;
}

.trade-request-btn {
    position: relative;
}

.trade-request-count {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    position: absolute;
    top: 20px;
    right: 1px;
}

.trade-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.btn-close {
    background: #444AE9;
    border: 1px solid;
    font-size: 20px;
}
