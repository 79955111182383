.logout-btn svg{
    color: black;
    margin-left: 0px;
}

.logout-btn{
    position: fixed !important;
    right: 40px;
    top: 20px;
    z-index: 1000;
}