*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    --bally_red: #EC0000;
}

@font-face {
    font-family: BallyThrill_Rg;
    src: url(./fonts/BallyThrill_Rg.ttf);
}
@font-face {
    font-family: BallyThrill_CdXBd;
    src: url(./fonts/BallyThrill_CdXBd.ttf);
}
@font-face {
    font-family: BallyThrill_Bd;
    src: url(./fonts/BallyThrill_Bd.ttf);
}
@font-face {
    font-family: BallyThrill_BdIt;
    src: url(./fonts/BallyThrill_BdIt.ttf);
}
@font-face {
    font-family: BallyThrill_CdBd;
    src: url(./fonts/BallyThrill_CdBd.ttf);
}
@font-face {
    font-family: BallyThrill_CdBdIt;
    src: url(./fonts/BallyThrill_CdBdIt.ttf);
}
@font-face {
    font-family: BallyThrill_CdXBdIt;
    src: url(./fonts/BallyThrill_CdXBdIt.ttf);
}
@font-face {
    font-family: BallyThrill_RgIt;
    src: url(./fonts/BallyThrill_RgIt.ttf);
}
/*these fonts and their associated usecases are taken from the ballys brand guideline typographic hierarchy.*/
h1, h2, h3, h4{
    font-family: BallyThrill_CdXBd, serif;
}
h5, h6{
    font-family: BallyThrill_Bd, serif;
}

.form{
    width: 60%;
    min-height: 100vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}