.sidebar-container{
    position: fixed;
    display: flex;
    flex-direction: row;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    /*
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("./images/purple-nav-bg.png");
*/
    background-color: #f3f3f3;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12),
    0 2px 2px rgba(0,0,0,0.12),
    0 4px 4px rgba(0,0,0,0.12);
}

.sidebar-item-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-left: 100px;
}

.dropdown-item:hover .dropdown-title{
    color: var(--bally_red);
    background-color: #200833;
}

.sidebar-item{
    display: flex;
    align-items: center;
    font-family: BallyThrill_CdXBd;
    font-size: 30px;
    padding: 10px;
    height: 100%;
}

.sidebar-item:hover{
    cursor: pointer;
    background-color: #200833;
    color: var(--bally_red);
}

.dropdown:hover{
    background-color: #200833;
    color: white !important;
}

.dropdown{
    position: relative;
    font-size: 23px;
    height: 100%;
}

.dropdown-item{
    display: flex;
    align-items: center;
    font-family: BallyThrill_CdXBd;
    font-size: 30px;
    padding: 15px;
    height: 100%;
}

.dropdown-item:hover{
    cursor: pointer;
    color: #1a8fff;
    background-color: #464269;
}

.dropdown:hover .dropdown-content{
    display: block;
    background-color: #200833;
    color: var(--bally_red);
}

.dropdown-content{
    position: absolute;
    display: none;
}

.dropdown-item{
    color: white;
}



.navbar-logo{
    padding: 20px;
}