.login-container{
    height: 100vh;
    width: 100vw;
    background-color: #FFFFFF;
}

.login-btn-container{
    align-content: center;
    background-image: url("./images/purple-background.png");
    background-size: 200%;
    width: 30vh;
    height: 30vh;
    margin: auto;
    margin-top: 20vh !important;
}

@keyframes buttonhover {
    0% {background-color: #0072e5 !important;}
    100% {background-color: #0072e5 !important;}
}

.login-btn{
    color: white;
    height: 5vh;
    border: none !important;
    background-color: #0072e5 !important;
    border-radius: 0 !important;
    width: 60%;
    position: relative;
    top: 15vh;
    left: 20%;
    font-size: 2rem !important;
    text-transform: none !important;
}

.login-btn:hover{
    cursor: pointer;
    background-color: #025db9 !important;
    animation: buttonhover linear;
    transition: 150ms;
}

.login-header{
    display: flex;
    width: 100vw;
    height: 80px;
    background-color: #f3f3f3;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12),
    0 2px 2px rgba(0,0,0,0.12),
    0 4px 4px rgba(0,0,0,0.12);
}

.bally-logo{
    padding: 20px;
}

.login-massage-text{
    text-transform: uppercase;
    height: 5vh;
    width: 30vh;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    top: 5vh;
    line-height: 5vh;
    font-size: 3rem;
}

@media only screen and (max-width: 600px)  {
    .login-btn-container{
        background-size: 250%;
        margin:auto;
        width: 80vw;
        height: 80vw;
    }
    .login-massage-text {
        width: 80vw;
        top: 20vw;
    }
    .login-btn{
        top: 40vw;
        height: 15vw;
    }
}