:disabled {
  color: rgb(193, 193, 193);
}

.background {
  font-family: sans-serif;
  min-height: 100vh;
  background-color: #f2f2ff;
  display: block;
  margin: 0;
  padding: 20px;
  width: 100%;
}

.optional{
  color: #777777;
  font-style: italic;
}

.required{
  color: red;
  font-style: italic;
}

.admin {
  min-height: 60vh;
}

.admin form {
  width: 50%;
  margin: auto;
}

.admin-header-page {
  font-family: BallyThrill_CdBd;
  color: var(--bally_red);
  font-size: 24pt;
  grid-column: 1;
  grid-row: 1;
}

.admin-description{
  font-family: BallyThrill_Rg;
  font-size: 18px;
  margin-bottom: 10px;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  height: 400px;
  overflow-y: scroll;
}

.page6{
  padding-top: 20px;
  height: 400px;
}

.page6>admin-header-page{
  padding-bottom: 20px;
}

.pageNext {
  display: flex;
  margin: auto;
  text-align: center;
  height: 75px;
  background-color: #f3f3f3;
}

.stepper-background {
  display: flex;
  margin: auto;
  height: 50px;
  background-color: #f3f3f3;
}

.stepper {
  display: flex;
  margin: auto;
  align-items: center;
  width: 25%;
  justify-content: center;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.lineBetween {
  flex-grow: 1;
  border-bottom: 1px solid black;
  margin: 5px
}

.admin-select {
  width: 50px !important;
  height: 1.5rem;
  margin: 1em;
  border-radius: 3px;
  transition: all 375ms ease-in-out;
}

.admin-submit:disabled{
  background-color: #8b8b91;
}

.admin-submit:hover:disabled{
  background-color: #8b8b91 !important;
  color: #ffffff !important;
  cursor: not-allowed;
}

.admin-select option {
  font-weight: bold;
}

.admin-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: BallyThrill_Rg;
  font-size: 18px;
  height: 2em;
  width: 8em;
  border: none;
  background-color: black;
  color: white;
  margin: auto;
  cursor: pointer;
}

.admin-header {
  width: 100%;
  padding-bottom: 30px;
  margin: auto;
  color: var(--bally_red);
  text-align: center;
  font-size: 42pt;
  font-family: BallyThrill_CdXBd;
}

.admin-submit:hover{
  background-color: #1976d2;
}

.generate{
  font-family: sans-serif;
  text-align: center;
  display: block;
  margin: 0;
  width: 100%;
}

.generate_column{
  margin: 0.2em;
}

.generate_label{
  font-size: 16px;
  font-family: sans-serif;
  width: 100%;
  margin: 1.0em;
  color: #151516;
}

.generate_header{
  width: 100%;
  padding: 1.2em;
  border-bottom: 1px solid var(--border-color);
  margin: auto;
  color: #151516;
}

.generate_year{
  width: 100%;
  padding: 0.1em;
  border-bottom: 1px solid var(--border-color);
  margin: auto;
  color: #151516;
}


/*CONFIRM*/

.confirm-icon{
  transform: scale(2);
  margin-bottom: 0.65em;
  color: #151516;
}

.confirm-label{
  color: #151516;
  font-family: sans-serif;
  font-size: 18px;
}

.confirm-box{
  text-align: center;
  color: #151516;
  border: 1px solid #F2F2FF;
  border-color: #151516;
  box-shadow: -9px 9px 20px rgba(158, 159, 184, 0.09);
  border-radius: 5px;
}

.confirm-button{
  font-family: sans-serif;
  width: 7.0em;
  min-width: 5.0em;
  margin-left: 6% !important;
  margin-right: 6% !important;
  margin-top: 6% !important;
  background-color: #444AE9 !important;
  cursor: pointer;
}

.cancel-button{
  font-family: sans-serif;
  width: 7.0em;
  min-width: 5.0em;
  margin-left: 6% !important;
  margin-right: 6% !important;
  margin-top: 6% !important;
  background-color: #151516 !important;
  cursor: pointer;
}

.cellWrapper{
  width: 500px;
}

.cellWrapper-cell {
  padding: 10px 5px 10px 5px;
}

.appointment_preview_disabled{
  color: #a9a9a9 !important;
  background-color: #dedede !important;
}

.appointment_preview:after{
  content: "Remove";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 50px;
  padding: 0 10px 0 10px;
  color: #777777;
  font-size: 13px;
  position: absolute;
  right: -70px;
  top: 0px;
}

.appointment_preview_disabled:after{
  content: none;
  cursor: pointer;
}

.appointment_preview{
  position: relative;
  cursor: pointer;
  background-color: #f3f3f3;
  border: 1px #e3e3e3 solid;
  margin-top: 8px !important;
  margin-bottom: 10px !important;
  margin: auto;
  width: 90%;
  height: 50px;
}

.tag{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  height: 100%;
  background-color: #1a8fff;
}

.appointment_preview:hover{
  background-color: #1a8fff;
  color: white;
  cursor: pointer;
  transition: 80ms ease-in;
}


@media only screen and (max-width: 600px) {
  .admin-header {
    font-size: 18px;
  }
 
  .rmdp-container input {
    width: 100% !important;
    height: 2.5rem;
    margin: 1em !important;
  }
  .admin-select {
    margin: 0px !important;
    width: 100% !important;
    height: 2.5rem;
  }
  .admin-label {
    margin-top: 2rem !important;
  }

  .admin-submit {
    margin: 9px 0px 0px !important;
    width: 100% !important;

  }
}