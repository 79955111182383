.overview-table {
  width: 60vw !important;
  /*max-height: 62vh;*/
  /*min-height: 85vh !important;*/
  margin: auto;
  margin-top: 2rem;
  overflow: scroll;
  overflow-y:auto;
  box-shadow: none !important;
}

.overview-table > table {
  min-width: 100%;
}

.overview-cell > span{
  width: 20%;
  text-align: center;
  font-size: 14px;
  display: flex;
  /*flex-direction: column;*/
  /*justify-content: center;*/
}

/*.date-cell, .name-cell{*/
/*  width: 30% !important;*/
/*}*/

/*To see whats booked*/
.booked{
  color: #9F9F9F !important;
  background-color: #e8e8e8 !important;
}

.overview-cell {
  display: flex !important;
  justify-content: space-between;
  font-size: 16px !important;
  border: 0 !important;
  font-family: BallyThrill_Rg !important;
}
.overview-tableBody tr:nth-child(odd){
  background-color: #f5f5f5;
}

.overview-datepicker {
  font-family: BallyThrill_Rg !important;
  font-size: 13pt;
  display: flex;
  justify-content: center;
  margin: 10px auto 20px auto;
}

.overview-datepicker > div {
  width: 100%
}

.overview-datepicker > div > input {
  width: 100%
}

.overview-datepicker input{
  height: 26pt !important;
  font-size: 12pt;
  border-radius: 0;
}

.container {
  padding-bottom: 10%;
}

.overview-background {
  background-color: #f2f2ff;
  width: 100%;
  height: 100%;
}

.print-button{
  color: black !important;
  position: fixed !important;
  right: 110px;
  top: 20px;
  z-index: 1000;
}

.table-header{
  font-size: 18pt !important;
  text-transform: uppercase;
  background-color: transparent !important;
  color: #EC0000 !important;
  display: inline !important;
  align-items: center !important;
  border: 0 !important;
}

.overview-searching-header{
  font-size: 11pt !important;
  text-transform: uppercase;
  background-color: transparent !important;
  color: #000000 !important;
  display: inline !important;
  align-items: center !important;
  border: 0 !important;
  padding: 0 !important;
}

.overview-quick-filter-buttons button{
  border: 0;
  height: 26pt;
  width: 250px;
  background-color: #0072e5;
  color: #FFFFFF;
  cursor: pointer;
  font-family: BallyThrill_Rg;
}

.overview-quickfilter-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.overview-filteroption{
  font-weight: bold;
}

.overview-table-header{
  background-color: #f5f5f5;
  font-weight: bold !important;
  text-transform: uppercase;
}

.datesegment-cell {
  font-family: BallyThrill_Rg !important;
  font-weight: bold !important;
  font-size: 16px !important;
  padding: 15px !important;
  border: 0 !important;
}

@media print {
  @page { margin: 5mm 5mm 5mm 5mm; }
  .overview-datepicker, .overview-searchIcon, .overview-searchGlass, .print-container, .logout-container, .footer, .table-header {
    display: none !important;
  }
  .overview-cell{
    padding: 10px !important;
    background-color: white !important;
  }
}
