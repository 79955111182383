.calendar-container {
    position: relative;
}

.skip-button {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 20px 40px;
    font-size: 24px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.skip-button:hover {
    background-color: #0056b3;
}


.calendar-body {
    position: relative;
    z-index: 1;
}
