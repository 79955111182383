.rolesetting-table {
  width: 60% !important;
  margin: auto;
  max-height: 62vh;
  overflow: scroll;
  overflow-y:auto;
}

.rolesetting-table Table {
  min-width: 100%;
  color: #EC0000 !important;
}

.rolesettings-header {
  color: #EC0000 !important;
  font-family: BallyThrill_CdXBd !important;
  padding-bottom: 15px;
  font-size: 46pt;
  text-transform: uppercase;
}
.roles-button{
  width: 200px !important;
  font-family: BallyThrill_Rg;
  font-size: 14pt !important;
  text-transform: none !important;
}

.delete-button{
  width: 50px !important;
  font-family: BallyThrill_Rg;
  font-size: 10pt !important;
  text-transform: none !important;
  background-color: #EC0000 !important;
}

.searchBox {
  position: absolute;
  top: 50px;
  left: calc(100vw/2 + 95px);
  transform: translate(-50%, -50%);
  background: #2f3640;
  border-radius: 40px;
  padding: 10px;
}

.searchBox:hover > .searchInput {
  width: 240px;
  padding: 0 6px;
}

.searchBox:hover > .searchButton {
  background: white;
  color: #2f3640;
}

.container {
  padding-bottom: 10%;
}

.searchButton {
  color: white;
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2f3640;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0;
}

.overview-cell > div {
  width: 33%;
  font-family: BallyThrill_Rg !important;
  margin: auto;
  font-size: 14pt;
}


.overview-cell button {
  width: 3rem;
  height: 3rem;
}

.change-btn {
  transform: scale(1.15);
}

.App{
  padding-top: 100px;
}

h1, h2, h3, h4{
    font-family: BallyThrill_CdXBd, serif;
}
h5, h6{
    font-family: BallyThrill_Bd, serif;
}

@media screen and (max-width: 600px) {
  .rolesetting-table {
    width: 100% !important;
    margin-top: 8rem;

  }
  .overview-cell {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px !important;

  }
  .overview-cell button {
    width: 60% !important;
    height: 3rem;
  }
  .overview-cell div {
    font-size: 18px !important;
    width: 50%;

  }
  .overview-cell div:not(:last-child) {
    border-bottom: 1px solid black;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .searchBox {
    width: 90%;
    top: 3.5rem;
  }


}

