.App {
    font-family: sans-serif;
    text-align: center;
    min-height: 100vh;
    background-color: white;
    padding-top: 200px;
    margin: 0;
    width: 100%;
}

.App > h1 {
    color: var(--bally_red);
}

/* FONT IMPORTS */

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #d8d8fc #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #bdbdff;
    border-radius: 10px;
    border: 0 none #ffffff;
}

.icon {
    font-family: "Material Icons", serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
}

/* VARIABLES */

:root {
    --main-color: #1a8fff;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
}

/* GENERAL */

* {
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    background: var(--bg-color);
    position: relative;
}

.week-header {
    margin-bottom: 10px !important;
    text-transform: uppercase;
}

main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
}

/* GRID */

.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}

.row-middle {
    align-items: center;
}

.col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.col-start {
    justify-content: flex-start;
    text-align: left;
}

.arrow {
    height: 52px;
    transform: scale(2.5);
    color: #0072e5;
    margin-top: 1em;
    cursor: pointer;
}

.col-center {
    justify-content: center;
    text-align: center;
}

.calendar-cell {
    border: 1px solid var(--border-color);
    min-height: 530px;
}

.col-center > div {
    font-style: normal;
    font-size: 20px;
    text-align: center;
    color: #151516;
}

.col-center > span {
    font-size: 14px;
    font-weight: 300;
    color: #151516;
}

.col-end {
    justify-content: flex-end;
    text-align: right;
}

/* Calendar */

.calendar {
    display: block;
    position: relative;
    width: 100%;
    margin: auto;
}

.calendar-body {
    max-height: 530px;

}

.calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
}

.calendar .header .icon {
    cursor: pointer;
    transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
    /*transform: scale(1.75);*/
    transition: 0.25s ease-out;
    color: var(--main-color);
}

.calendar .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar .header .icon:last-of-type {
    margin-right: 1em;
}

.calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
    padding: 0.75em 0;
}

.calendar .body .cell {
    position: relative;
    padding-bottom: 1em;
    height: 45vh;
    overflow-y: auto !important;
    border-right: 1px solid var(--border-color);
    background: var(--neutral-color);
    transition: 0.25s ease-out;
    font-size: 1.5em;
}

.appointment_time {
    cursor: pointer;
    background-color: #f3f3f3;
    border: 1px #e3e3e3 solid;
    color: black !important;
    margin-top: 8px !important;
    margin-bottom: 10px !important;
    margin: auto;
    width: 90%;
}

.calendar .body .today {
    /*border-image: linear-gradient(45deg, #efeffc 0%, #f14f4f 20%);*/
    /*border-image-slice: 1;*/
    border: 1px solid #EC0000;
    /* background-color:#F2F2FF; */
}

.calendar .body .row {
    border-bottom: 1px solid var(--border-color);
}

/* .calendar .body .row:last-child {
  border-bottom: none;
} */

.calendar .body .cell:last-child {
    border-right: none;
}

.calendar .body .cell .number {
    font-size: 82.5%;
    line-height: 1;
    margin: auto;
    font-weight: 700;
    color: #151516;
    margin-top: 1em;
}

.calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
}

.calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
}

@media only screen and (max-width: 380px) {
    .arrow {
        transform: scale(2.1) !important;
    }

    .col-center div {
        font-size: 16px;
    }

    .col-center span {
        display: none;
    }

    .header {
        align-items: center;
    }

    .col-start {
        margin-left: 15px;
    }

    .col-end {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 800px) {
    .calendar, .body, .list-table {
        width: 99%;
    }

    .list-header {
        display: none;
    }

    .calendar {
        min-width: 95vw;
    }

    .app h1 {
        font-size: 2em !important;
    }

    .regular-button {
        display: none;
    }

    .buttons-container {
        right: auto;
        margin: 0;
    }

    .list-table {
        margin-top: 10vh;
        max-height: 70vh;
    }
}

#calendar-header {
    /* This should always be displayed, even if the user scrolls down */
    z-index: 2;

}

.calendar-header {
    background-color: #f8f8f8;
    padding: 10px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 2;
}

.calendar-header.fixed {
    position: fixed;
    top: 78px;

}